import React from 'react';
import { GridSubHeadline } from '../../../components/pageGrid/GridSubHeadline';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import { GridTwoPanels } from '../../../components/pageGrid/GridTwoPanels';
import { BusinessHours, Weekday } from '../../../codegen/location';
import { WEEKDAYS_TO_TRANSLATION_KEY } from '../../../i18n/enumMappings';
import { GridPanel } from '../../../components/pageGrid/GridPanel';
import { Grid } from '../../../components/pageGrid/Grid';

type LocationOpeningHoursProps = {
  gridMaxColumns: number;
  openingHours: {
    entry: BusinessHours;
    exit: BusinessHours;
  };
};

export const LocationOpeningHours: React.FC<LocationOpeningHoursProps> = ({
  gridMaxColumns,
  openingHours,
}) => {
  const isAvailable247 = (businessHours: BusinessHours) => {
    return Object.values(Weekday).some((weekday) => {
      const businessHoursOnWeekday = businessHours.find(
        (businessHour) => businessHour.weekday === weekday,
      );

      return (
        businessHoursOnWeekday?.open === '00:00' &&
        businessHoursOnWeekday?.close === '23:59'
      );
    });
  };

  const isAlwaysClosed = (businessHoursOnWeekday: BusinessHours) => {
    return businessHoursOnWeekday.length === 0;
  };

  const isValidTimeStamps = (
    open: string | undefined,
    close?: string | undefined,
  ) => {
    // validate open timestamp before close timestamp
    return (
      open &&
      close &&
      Number.parseFloat(open.replace(':', '')) <
        Number.parseFloat(close.replace(':', ''))
    );
  };

  const renderWeekdays = (businessHours: BusinessHours) => {
    if (isAvailable247(businessHours)) {
      return <CustomFormattedMessage id={'locationDetails.available247'} />;
    }

    return Object.values(Weekday).map((weekday) => {
      const businessHoursOnWeekday = businessHours.filter(
        (businessHour) => businessHour.weekday === weekday,
      );

      return (
        <Grid
          key={weekday}
          columns={2}
        >
          <GridPanel
            dataTestId={'weekday'}
            columns={1}
          >
            <CustomFormattedMessage
              id={WEEKDAYS_TO_TRANSLATION_KEY[weekday as Weekday]}
            />
          </GridPanel>
          <GridPanel
            dataTestId={'hours-of-weekday'}
            columns={1}
          >
            {renderOpeningHours(businessHoursOnWeekday)}
          </GridPanel>
        </Grid>
      );
    });
  };

  const renderOpeningHours = (businessHoursOnWeekday: BusinessHours) => {
    if (isAlwaysClosed(businessHoursOnWeekday)) {
      return (
        <div>
          <CustomFormattedMessage id={'locationDetails.closed'} />
        </div>
      );
    }

    return businessHoursOnWeekday.map((hour) => {
      if (!isValidTimeStamps(hour.open, hour.close)) {
        return (
          <div key={`${hour.weekday}_${hour.open}`}>
            <CustomFormattedMessage
              id={'locationDetails.noInformationAvailable'}
            />
          </div>
        );
      }

      return (
        <div key={`${hour.weekday}_${hour.open}`}>
          {hour.open}-{hour.close}
        </div>
      );
    });
  };

  return (
    <>
      <GridSubHeadline columns={gridMaxColumns}>
        <CustomFormattedMessage id="locationDetails.openingHours" />
      </GridSubHeadline>
      <GridTwoPanels
        commonClass={'height-100pct'}
        leftColumns={2}
        rightColumns={4}
        leftDataTestId={'arrivals'}
        rightDataTestId={'arrival-days-hours'}
        leftContent={<CustomFormattedMessage id={'locationDetails.arrival'} />}
        rightContent={renderWeekdays(openingHours.entry)}
      />
      <GridTwoPanels
        commonClass={'height-100pct margin-top-20'}
        leftColumns={2}
        rightColumns={4}
        leftDataTestId={'departures'}
        rightDataTestId={'departure-days-hours'}
        leftContent={
          <CustomFormattedMessage id={'locationDetails.departure'} />
        }
        rightContent={renderWeekdays(openingHours.exit)}
      />
    </>
  );
};
