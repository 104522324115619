import { BookingDetailsForm } from './BookingDetailsForm';
import { GridHeadline } from '../../../components/pageGrid/GridHeadline';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import { Grid } from '../../../components/pageGrid/Grid';

export const BookingDetailsPage = () => {
  return (
    <Grid columns={1}>
      <GridHeadline columns={1}>
        <CustomFormattedMessage id={'bookingDetailsPage.headline'} />
      </GridHeadline>
      <BookingDetailsForm />
    </Grid>
  );
};
