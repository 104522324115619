import { appConfiguration } from '../configuration/appConfiguration';

export type MarkerData = {
  detailsUrl: string;
  enableContextMenu: boolean;
};

export type MarkerPosition = {
  lat: number;
  lng: number;
};

type MarkerProps = {
  name: string;
  markerOnHover: boolean;
};

export type SimplePayPoi = {
  id: string;
  data: MarkerData;
  markerProps: MarkerProps;
  position: MarkerPosition;
};

export type RenderSimplePayPoisEvent = {
  type: 'EVENT_RENDER_SIMPLE_PAY_POIS';
  payload: {
    simplePayPois: SimplePayPoi[];
  };
};

export type FleetMonitorEvent = RenderSimplePayPoisEvent;

export const useMessaging = () => {
  const sendEvent = (event: FleetMonitorEvent) => {
    window.parent.postMessage(event, appConfiguration.messaging.targetOrigin);
  };

  return {
    sendEvent,
  };
};
