import moment, { Moment } from 'moment-timezone';

export class DateUtils {
  static roundTimeToNextQuarter = (time: Moment): Moment => {
    const minutes = time.minute();
    const remainder = minutes % 15;
    const roundUp = remainder !== 0;

    if (roundUp) {
      return time.add(15 - remainder, 'minutes').seconds(0);
    } else {
      return time.seconds(0);
    }
  };

  static parseDateTime = (
    timezone: string,
    date?: Moment,
    time?: Moment,
  ): string | undefined => {
    if (!date || !time) {
      return undefined;
    }

    return this.combineDateAndTime(date, time, timezone).toISOString(true);
  };

  static combineDateAndTime = (
    date: Moment,
    time: Moment,
    timezone: string,
  ): Moment => {
    const dateFormat = 'YYYY-MM-DD';
    const formattedDate = date.format(dateFormat);

    const timeFormat = 'HH:mm';
    const formattedTime = time.format(timeFormat);

    return moment.tz(
      `${formattedDate}-${formattedTime}`,
      `${dateFormat}-${timeFormat}`,
      timezone,
    );
  };
}
