import React, { ReactNode } from 'react';
import classNames from 'classnames';

type Error = {
  message?: string;
};

type FormFieldProps = {
  'error'?: Error;
  'className'?: string;
  'children'?: ReactNode;
  'data-testid'?: string;
};

export const FormField: React.FC<FormFieldProps> = (props) => {
  const { error, className, children } = props;

  const hasError = !!error;

  const testid = props['data-testid'];
  return (
    <div
      className={classNames(
        'form-group',
        { 'has-feedback has-error': hasError },
        className,
      )}
      data-testid={testid}
    >
      {children}
      {hasError && (
        <>
          <span
            className="form-control-feedback rioglyph rioglyph-error-sign"
            data-testid={'form-field-error-sign'}
          />
          {error?.message && (
            <span
              className="help-block"
              data-testid={'form-field-error-message'}
            >
              <span>{error.message}</span>
            </span>
          )}
        </>
      )}
    </div>
  );
};
