import { Alert, AlertType } from './Alert';
import { CustomFormattedMessage } from '../i18n/i18n';

export const InfoTestEnvironment = () => {
  return (
    <Alert
      headline={<CustomFormattedMessage id={'environment.test.headline'} />}
      body={<CustomFormattedMessage id={'environment.test.body'} />}
      type={AlertType.WARNING}
      className={'margin-bottom-20'}
      dismissible
    />
  );
};
