export const ROOT_ROUTE = '/';
export const LOCATION_DETAILS_ROUTE = '/location';

export const locationDetailsRoute = (locationId: string) => {
  return `${LOCATION_DETAILS_ROUTE}?locationId=${locationId}`;
};

export const PARKING_BOOKING_ROUTE = '/parking-booking';
export const PARKING_BOOKING_DETAILS_ROUTE = `${PARKING_BOOKING_ROUTE}/details`;
export const PARKING_BOOKING_SUMMARY_ROUTE = `${PARKING_BOOKING_ROUTE}/summary`;

export const REDIRECT_ROUTE = '/redirect';
