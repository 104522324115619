export const appConfiguration = {
  login: {
    authority: import.meta.env.VITE_LOGIN_AUTHORITY,
    clientId: import.meta.env.VITE_LOGIN_CLIENT_ID,
    redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
    mock: import.meta.env.VITE_LOGIN_MOCK_LOGIN,
    requiredScopes: import.meta.env.VITE_LOGIN_REQUIRED_SCOPES,
  },
  messaging: {
    targetOrigin: import.meta.env.VITE_MESSAGING_TARGET_ORIGIN,
  },
  externalUrls: {
    simplePayBookingOverviewUrl: import.meta.env
      .VITE_SIMPLEPAY_BOOKING_OVERVIEW_URL,
    detailsWidgetUrl: import.meta.env.VITE_DETAILS_WIDGET_URL,
    upsellingWidgetUrl: import.meta.env.VITE_UPSELLING_WIDGET_URL,
  },
  api: {
    simplepay: {
      url: import.meta.env.VITE_SIMPLEPAY_API_URL,
    },
    location: {
      url: import.meta.env.VITE_LOCATION_API_URL,
      pageSize: import.meta.env.VITE_LOCATION_PAGE_SIZE,
    },
  },
  environment: {
    test: import.meta.env.MODE === 'test',
  },
};
