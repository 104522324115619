import * as RioDatePicker from '@rio-cloud/rio-uikit/DatePicker';
import { Moment } from 'moment/moment';
import { TimeConstraints } from 'react-datetime';

const TIME_FORMAT = 'HH:mm';

type TimePickerProps = {
  value?: string | Moment | Date;
  onChange?: (value: string | Moment, isValid: boolean) => void;
  timeConstraints?: TimeConstraints;
};

export const TimePicker = ({
  value,
  onChange,
  timeConstraints,
}: TimePickerProps) => {
  return (
    <RioDatePicker.default
      value={value}
      onChange={onChange}
      dateFormat={false}
      timeFormat={TIME_FORMAT}
      inputProps={{ placeholder: TIME_FORMAT }}
      timeConstraints={timeConstraints}
      className="margin-0"
    />
  );
};
