import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import { CustomFormattedMessage } from '../../i18n/i18n';
import { useMapMarkers } from '../../hooks/useMapMarkers';
import { useEffect, useState } from 'react';
import { Alert, AlertType } from '../../components/Alert';
import Button from '@rio-cloud/rio-uikit/Button';
import { useLocations } from '../../state/location/useLocations';
import { SimplePayPoi } from '../../hooks/useMessaging';
import { appConfiguration } from '../../configuration/appConfiguration';
import { ServiceType } from '../../codegen/location';
import classNames from 'classnames';

export const ServiceTypeFilter = () => {
  const { sendMarkers } = useMapMarkers();
  const [serviceTypeSelections, setServiceTypeSelections] = useState<
    Partial<Record<ServiceType, boolean>>
  >({
    [ServiceType.Parking]: true,
  });

  const selectedServiceTypes = Object.entries(serviceTypeSelections)
    .filter(([type, selected]) => selected)
    .map(([type]) => type as ServiceType);

  const { locations, error, refetch, isFetching } =
    useLocations(selectedServiceTypes);

  useEffect(() => {
    const markers: SimplePayPoi[] = (locations || []).map((l) => ({
      id: l.id,
      data: {
        detailsUrl: `${appConfiguration.externalUrls.detailsWidgetUrl}/location?locationId=${l.id}`,
        enableContextMenu: true,
      },
      markerProps: {
        name: l.name,
        markerOnHover: false,
      },
      position: {
        lat: +l.geoCoordinates.latitude,
        lng: +l.geoCoordinates.longitude,
      },
    }));

    sendMarkers(markers);
  }, [serviceTypeSelections, locations]);

  const isServiceSelected = (service: ServiceType) => {
    return serviceTypeSelections[service];
  };

  const toggleService = (service: ServiceType) => {
    setServiceTypeSelections({
      ...serviceTypeSelections,
      [service]: !isServiceSelected(service),
    });
  };

  return (
    <>
      <Checkbox
        checked={isServiceSelected(ServiceType.Parking)}
        onClick={() => toggleService(ServiceType.Parking)}
        data-testid="poi-filter-checkbox-parking"
      >
        <span
          className="rioglyph rioglyph-parking padding-left-5 text-size-16"
          aria-hidden="true"
        />
        <CustomFormattedMessage id={'common.serviceType.parking'} />
      </Checkbox>
      {error && (
        <Alert
          headline={
            <CustomFormattedMessage
              id={'locationFilter.getLocations.errorMessage.header'}
            />
          }
          body={
            <CustomFormattedMessage
              id={'locationFilter.getLocations.errorMessage.body'}
            />
          }
          footer={
            <>
              <Button
                bsStyle={Button.DANGER}
                className={classNames({ 'btn-loading disabled': isFetching })}
                onClick={() => {
                  refetch();
                }}
              >
                <CustomFormattedMessage id={'common.retry'} />
              </Button>
            </>
          }
          type={AlertType.DANGER}
          className={'margin-top-20'}
        />
      )}
    </>
  );
};
