import React, { ReactNode } from 'react';

type GridPanelProps = {
  columns: number;
  className?: string;
  children?: ReactNode;
  dataTestId?: string;
};

export const GridPanel: React.FC<GridPanelProps> = ({
  columns,
  className,
  children,
  dataTestId,
}) => {
  return (
    <div
      data-testid={dataTestId}
      className={`grid-colspan-${columns} ${className}`}
    >
      {children}
    </div>
  );
};
