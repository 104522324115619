import { useEffect, useState } from 'react';
import { useGetVehiclesQuery, Vehicle } from '../codegen/vehicles';

export const useSimplePayBookingState = () => {
  const [isSimplePayBooked, setIsSimplePayBooked] = useState(false);

  const { isLoading, data: vehicles } = useGetVehiclesQuery();

  useEffect(() => {
    const enabledVehicles =
      vehicles?.items.filter((v: Vehicle) => v.enabled) || [];

    if (enabledVehicles.length > 0) {
      setIsSimplePayBooked(true);
    }
  }, [isLoading, vehicles]);

  return {
    isSimplePayBooked,
    isLoading,
  };
};
