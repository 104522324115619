import { Alert, AlertType } from '../../../components/Alert';
import { CustomFormattedMessage } from '../../../i18n/i18n';

export const BookingFailedMessage = () => {
  return (
    <Alert
      headline={<CustomFormattedMessage id={'bookingSummary.error.headline'} />}
      body={<CustomFormattedMessage id={'bookingSummary.error.note'} />}
      type={AlertType.DANGER}
      className={'margin-bottom-20'}
    />
  );
};
