import React from 'react';
import { GridSubHeadline } from '../../../components/pageGrid/GridSubHeadline';
import { CustomFormattedMessage, useCustomIntl } from '../../../i18n/i18n';
import { GridTwoPanels } from '../../../components/pageGrid/GridTwoPanels';
import { MonetaryValue } from '../../../components/MonetaryValue';
import { PricePerDuration } from '../../../codegen/location';

type LocationPricesProps = {
  gridMaxColumns: number;
  prices: PricePerDuration[];
};

export const LocationPrices: React.FC<LocationPricesProps> = ({
  gridMaxColumns,
  prices,
}) => {
  const intl = useCustomIntl();

  const isValidPriceInformation = (pricePerDuration: PricePerDuration) => {
    if (!pricePerDuration.duration) {
      return false;
    }

    const [hours] = pricePerDuration.duration.split(':');
    const totalHours = Number(hours);
    return totalHours >= 1;
  };

  const calculateTime = (pricePerDuration: PricePerDuration) => {
    const [hours] = pricePerDuration.duration.split(':');
    const totalHours = Number(hours);
    const days = Math.round(totalHours / 24);

    if (totalHours === 1) {
      return `${totalHours} ${intl.formatMessage({ id: 'locationDetails.hour' })}`;
    }

    if (totalHours < 24) {
      return `${totalHours} ${intl.formatMessage({ id: 'locationDetails.hours' })}`;
    }

    if (days === 1) {
      return `1 ${intl.formatMessage({ id: 'locationDetails.day' })}`;
    }

    if (days > 1) {
      return `${days} ${intl.formatMessage({ id: 'locationDetails.days' })}`;
    }
  };

  return (
    <>
      <GridSubHeadline columns={gridMaxColumns}>
        <CustomFormattedMessage id="locationDetails.prices" />
      </GridSubHeadline>
      {prices.map(
        (parkingPrice, index) =>
          isValidPriceInformation(parkingPrice) && (
            <GridTwoPanels
              key={index}
              commonClass={'align-items-center'}
              leftColumns={2}
              rightColumns={4}
              leftDataTestId={`${parkingPrice.duration}-hours-label`}
              rightDataTestId={`${parkingPrice.duration}-hours-price`}
              leftContent={calculateTime(parkingPrice)}
              rightContent={
                <div className="max-width-100">
                  <MonetaryValue
                    amount={Number.parseFloat(parkingPrice.price.value)}
                    currency={parkingPrice.price.currency}
                  />
                </div>
              }
            />
          ),
      )}
      <div className={'margin-bottom-10'} />
    </>
  );
};
