import { CustomFormattedMessage } from '../../../i18n/i18n';
import { GridPanel } from '../../../components/pageGrid/GridPanel';

export const BookingSummaryLegalNotice = () => {
  return (
    <GridPanel columns={1}>
      <CustomFormattedMessage
        id={'bookingSummary.legalNotice'}
        values={{
          terms: (parts) => (
            <a
              className={'text-decoration-underline'}
              href={'https://www.yourtravis.com/terms-and-conditions/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              {parts}
            </a>
          ),
          regulations: (parts) => (
            <a
              className={'text-decoration-underline'}
              href={'https://www.yourtravis.com/truck-parking-regulations/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              {parts}
            </a>
          ),
        }}
      />
    </GridPanel>
  );
};
