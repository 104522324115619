import React from 'react';
import { GridPanel } from '../../../components/pageGrid/GridPanel';
import { InfoPopup } from '../../../components/InfoPopup';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import { Address } from '../../../codegen/location';

type LocationAddressProps = {
  address: Address;
};

export const LocationAddress: React.FC<LocationAddressProps> = ({
  address,
}) => (
  <>
    <GridPanel
      dataTestId={'address'}
      columns={4}
    >
      <p>
        {address.street}
        <br />
        {address.zipCode} {address.city}
      </p>
    </GridPanel>
    <GridPanel
      columns={2}
      className="height-40"
    >
      <InfoPopup placement="top-end">
        <CustomFormattedMessage id="locationDetails.infoPopoverText" />
      </InfoPopup>
    </GridPanel>
  </>
);
