import React, { ReactNode } from 'react';
import classNames from 'classnames';

type GridTwoPanelsProps = {
  leftColumns: number;
  rightColumns: number;
  leftContent: ReactNode;
  rightContent: ReactNode;
  commonClass?: string;
  leftDataTestId?: string;
  rightDataTestId?: string;
};

export const GridTwoPanels: React.FC<GridTwoPanelsProps> = ({
  leftColumns,
  rightColumns,
  leftContent,
  rightContent,
  commonClass = '',
  leftDataTestId = '',
  rightDataTestId = '',
}) => {
  return (
    <>
      <div
        data-testid={leftDataTestId}
        className={classNames(`grid-colspan-${leftColumns}`, commonClass)}
      >
        {leftContent}
      </div>
      <div
        data-testid={rightDataTestId}
        className={classNames(`grid-colspan-${rightColumns}`, commonClass)}
      >
        {rightContent}
      </div>
    </>
  );
};
