import { ServiceType } from '../../codegen/location';
import { useGetAllLocationsQuery } from './injectedLocationApi';

export const useLocations = (serviceTypes?: ServiceType[]) => {
  const { isFetching, data, error, refetch } = useGetAllLocationsQuery({
    serviceTypes,
  });

  if (serviceTypes?.length === 0) {
    return {
      isFetching: false,
      locations: [],
      refetch: () => {},
    };
  }

  return {
    isFetching,
    locations: data ? data.items : undefined,
    error,
    refetch,
  };
};
