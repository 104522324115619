import { useNavigate } from 'react-router-dom';
import { enterBookingFlow } from '../../parkingBooking/state/parkingBookingSlice';
import { PARKING_BOOKING_DETAILS_ROUTE } from '../../../routing/routes';
import { useAppDispatch } from '../../../state/store';
import React from 'react';
import { Grid } from '../../../components/pageGrid/Grid';
import { GridPanel } from '../../../components/pageGrid/GridPanel';
import { LocationAddress } from '../subComponents/LocationAddress';
import { LocationOpeningHours } from '../subComponents/LocationOpeningHours';
import { LocationServices } from '../subComponents/LocationServices';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import Button from '@rio-cloud/rio-uikit/Button';
import { ParkingLocation } from '../../../codegen/location';
import { LocationPrices } from '../subComponents/LocationPrices';

export type ParkingLocationDetailsProps = {
  location: ParkingLocation;
};

const gridMaxColumns = 6;

export const ParkingLocationDetails: React.FC<ParkingLocationDetailsProps> = ({
  location,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isBookable =
    location.digitalServices
      ?.filter((digitalService) => digitalService.serviceType === 'PARKING')
      .some((digitalService) => digitalService.onlinePaymentSupported) || false;

  const startBookingProcessForParking = () => {
    dispatch(
      enterBookingFlow({
        locationId: location.id!!,
        timezone: location.timezone!!,
      }),
    );
    navigate(PARKING_BOOKING_DETAILS_ROUTE);
  };

  return (
    <Grid
      columns={gridMaxColumns}
      className={'align-items-center'}
    >
      <LocationAddress address={location.address} />
      <LocationOpeningHours
        gridMaxColumns={gridMaxColumns}
        openingHours={location.openingHours}
      />
      <LocationPrices
        gridMaxColumns={gridMaxColumns}
        prices={location.prices}
      />
      <LocationServices
        gridMaxColumns={gridMaxColumns}
        parkingOptions={location.parkingOptions}
      />
      <GridPanel
        columns={gridMaxColumns}
        className="margin-top-20 text-right"
      >
        {!isBookable && (
          <div
            data-testid="errorMessage-notBookable"
            className="label label-danger margin-right-10"
          >
            <CustomFormattedMessage id="bookingDetailsPage.errorMessage.notBookable" />
          </div>
        )}
        <Button
          disabled={!isBookable}
          onClick={startBookingProcessForParking}
          bsStyle="primary"
        >
          <CustomFormattedMessage id="locationDetails.bookService" />
        </Button>
      </GridPanel>
    </Grid>
  );
};
