import React, { ReactNode } from 'react';
import classNames from 'classnames';

type GridProps = {
  columns: number;
  className?: string;
  children?: ReactNode;
};

export const Grid: React.FC<GridProps> = ({
  columns,
  children,
  className = '',
}) => {
  return (
    <div
      className={classNames('display-grid', `grid-cols-${columns}`, className)}
    >
      {children}
    </div>
  );
};
