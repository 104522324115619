import de from './de.json';
import en from './en.json';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import {
  FormattedDate,
  FormattedMessage,
  IntlProvider,
  MessageDescriptor,
  useIntl,
} from 'react-intl';
import { Options as IntlMessageFormatOptions } from 'intl-messageformat/src/core';
import React from 'react';

export const DEFAULT_LOCALE = 'en';

export type Translations = typeof en;
export type TranslationsKeys = keyof Translations;
export type TranslationsLabelValues = Record<
  string,
  PrimitiveType | FormatXMLElementFn<string, string>
>;

type TranslationsMap = {
  [key: string]: Translations;
};

export const supportedTranslations: TranslationsMap = {
  de,
  en,
};

export const getMessagesForLocale = (
  locale: string,
): Record<string, string> => {
  const supportedLocals = Object.keys(supportedTranslations);

  const selected =
    supportedLocals.find((l) => locale.startsWith(l)) || DEFAULT_LOCALE;

  return supportedTranslations[selected];
};

type CustomFormattedMessageProps = Record<
  string,
  | string
  | number
  | boolean
  | object
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | React.ReactFragment
  | React.ReactPortal
  | Date
  | FormatXMLElementFn<React.ReactNode, React.ReactNode>
  | TranslationsLabelValues
  | null
  | undefined
> & {
  id: TranslationsKeys;
};

export const CustomFormattedMessage = ({
  id,
  ...rest
}: CustomFormattedMessageProps) => {
  return (
    <FormattedMessage
      id={id}
      {...rest}
    />
  );
};

export const useCustomIntl = () => {
  const intl = useIntl();

  const customFormatMessage = (
    descriptor: MessageDescriptor & {
      id?: TranslationsKeys;
    },
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    options?: IntlMessageFormatOptions,
  ): string => {
    return intl.formatMessage(descriptor, values, options) as string;
  };

  return {
    ...intl,
    formatMessage: customFormatMessage,
  };
};

export const defaultDateFormat: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hourCycle: 'h23',
};

export const CustomFormattedDate = ({
  value,
  dateFormat = defaultDateFormat,
}: {
  value?: number | string;
  dateFormat?: Intl.DateTimeFormatOptions;
}) => {
  const dateLocale = 'en-GB';

  return (
    <IntlProvider locale={dateLocale}>
      <FormattedDate
        value={value}
        {...dateFormat}
      />
    </IntlProvider>
  );
};
