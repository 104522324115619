import React, { ReactNode } from 'react';
import classNames from 'classnames';

type GridSubHeadlineProps = {
  columns: number;
  children?: ReactNode;
  dataTestId?: string;
};

export const GridSubHeadline: React.FC<GridSubHeadlineProps> = ({
  columns,
  children,
  dataTestId = '',
}) => {
  return (
    <div
      data-testid={dataTestId}
      className={classNames(
        'text-medium',
        'padding-top-5',
        'padding-bottom-5',
        'border-top-0',
        `grid-colspan-${columns}`,
      )}
    >
      {children}
    </div>
  );
};
