import { useGetParkingLocationQuery } from './injectedLocationApi';

export const useParkingLocation = (id: string) => {
  const { isFetching, data, error, refetch } = useGetParkingLocationQuery({
    id,
  });

  if (!id) {
    return {
      isFetching: false,
      locationData: undefined,
      refetch: () => {},
    };
  }

  return {
    isFetching,
    locationData: data,
    error,
    refetch,
  };
};
