import React, { ReactNode } from 'react';
import classNames from 'classnames';

type GridHeadlineProps = {
  columns: number;
  children?: ReactNode;
  className?: string;
};

export const GridHeadline: React.FC<GridHeadlineProps> = ({
  columns,
  children,
  className = '',
}) => {
  return (
    <div
      className={classNames(
        'text-medium',
        'text-size-16',
        'padding-bottom-10',
        'border-top-0',
        `grid-colspan-${columns}`,
        className,
      )}
    >
      {children}
    </div>
  );
};
