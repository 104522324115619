import { useSearchParams } from 'react-router-dom';
import { ParkingLocationDetails } from './parking/ParkingLocationDetails';
import { useParkingLocation } from '../../state/location/useParkingLocation';
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import Button from '@rio-cloud/rio-uikit/Button';
import { CustomFormattedMessage } from '../../i18n/i18n';
import { Alert, AlertType } from '../../components/Alert';
import classNames from 'classnames';

export const LocationDetailsPage = () => {
  const [searchParams] = useSearchParams();

  const locationId = searchParams.get('locationId');
  const { locationData, isFetching, error, refetch } = useParkingLocation(
    locationId!,
  );

  if (isFetching) {
    return (
      <div className="display-flex">
        <div>
          <ContentLoader
            height={40}
            width={40}
          />
        </div>
        <div className="margin-left-15">
          <ContentLoader />
          <ContentLoader />
        </div>
      </div>
    );
  }

  if (error || !locationData) {
    return (
      <Alert
        headline={
          <CustomFormattedMessage
            id={'locationFilter.getLocations.errorMessage.header'}
          />
        }
        body={
          <CustomFormattedMessage
            id={'locationFilter.getLocations.errorMessage.body'}
          />
        }
        footer={
          <>
            <Button
              bsStyle={Button.DANGER}
              className={classNames({ 'btn-loading disabled': isFetching })}
              onClick={() => {
                refetch();
              }}
            >
              <CustomFormattedMessage id={'common.retry'} />
            </Button>
          </>
        }
        type={AlertType.DANGER}
        className={'margin-top-20'}
      />
    );
  }

  return <ParkingLocationDetails location={locationData} />;
};
