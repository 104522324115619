import {
  RenderSimplePayPoisEvent,
  SimplePayPoi,
  useMessaging,
} from './useMessaging';

export const useMapMarkers = () => {
  const { sendEvent } = useMessaging();

  const sendMarkers = (markers: SimplePayPoi[]) => {
    const event: RenderSimplePayPoisEvent = {
      type: 'EVENT_RENDER_SIMPLE_PAY_POIS',
      payload: {
        simplePayPois: markers,
      },
    };

    sendEvent(event);
  };

  return { sendMarkers };
};
