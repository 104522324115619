import { DEFAULT_LOCALE } from '../../i18n/i18n';
import { User, UserManager } from 'oidc-client';
import {
  EVENT_USER_LANGUAGE_CHANGED,
  EVENT_USER_PROFILE_CHANGED,
} from '@rio-cloud/rio-user-menu-component';
import { appConfiguration } from '../../configuration/appConfiguration';
import { routeStorage } from '../../routing/routeStorage';
import {
  AccessToken,
  userSessionExpired,
  renewUserSession,
} from './tokenSlice';
import { accessToken } from './accessToken';
import { store } from '../store';
import { setLocale } from '../../i18n/localeSlice';

export type RioUser = {
  accessToken: string;
  accountId: string;
  locale: string;
};

export const MOCK_USER: RioUser = {
  accessToken: 'mocked-access-token',
  accountId: '8ef04c71-8105-4bfb-a97c-a70db9d66975',
  locale: DEFAULT_LOCALE,
};

export const login = async (): Promise<RioUser | undefined> => {
  if (appConfiguration.login.mock) {
    return MOCK_USER;
  }

  const isRedirectedFromAuthServer = window.location.href.startsWith(
    appConfiguration.login.redirectUri,
  );

  return isRedirectedFromAuthServer ? signInFromRedirect() : redirectToSignIn();
};

const signInFromRedirect = async () => {
  const userManager = configureUserManager(createUserManager());
  document.addEventListener(EVENT_USER_PROFILE_CHANGED, () =>
    userManager.signinSilent(),
  );
  document.addEventListener(EVENT_USER_LANGUAGE_CHANGED, () =>
    userManager.signinSilent(),
  );

  const user = await userManager.signinRedirectCallback();
  return mapUser(user);
};

const mapUser = (user: User): RioUser => {
  const accountId = user.profile?.account || user.profile['cognito:username'];
  return {
    accessToken: user.access_token,
    accountId,
    locale: user.profile.locale || DEFAULT_LOCALE,
  };
};

const redirectToSignIn = async () => {
  routeStorage.saveRoute(window.location.pathname + window.location.search);

  const userManager = createUserManager();
  await userManager.signinRedirect();
  return Promise.resolve(undefined);
};

export const adaptPublishedInfo = (result: User): SessionRenewedResult => ({
  accessToken: result.access_token,
  locale: result.profile?.locale ?? DEFAULT_LOCALE,
});

export type SessionRenewedResult = {
  accessToken: AccessToken;
  locale: string;
};

export const createUserManager = () => {
  const setting = {
    authority: appConfiguration.login.authority,
    client_id: appConfiguration.login.clientId,
    response_type: 'code',
    scope: appConfiguration.login.requiredScopes,
    redirect_uri: appConfiguration.login.redirectUri,
    silent_redirect_uri: appConfiguration.login.redirectUri,
    includeIdTokenInSilentRenew: false,
    staleStateAge: 600,
    automaticSilentRenew: true,
    loadUserInfo: false,
  };
  return new UserManager(setting);
};

export const configureUserManager = (userManager: UserManager) => {
  userManager.events.addUserLoaded((user) => {
    const result = adaptPublishedInfo(user);
    store.dispatch(renewUserSession(result));
    store.dispatch(setLocale(result.locale));
  });

  userManager.events.addUserUnloaded(() => {
    handleSessionExpired();
  });

  userManager.events.addSilentRenewError(() => {
    retrySigninSilent(userManager);
  });

  userManager.events.addUserSignedOut(() => {
    handleSessionExpired();
  });

  return userManager;
};

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const retrySigninSilent = (userManager: UserManager) => {
  userManager.signinSilent().catch((error: Error) => {
    if (error.message === 'login_required') {
      handleSessionExpired();
    } else {
      setTimeout(
        () => retrySigninSilent(userManager),
        RETRY_SIGNIN_TIMEOUT_IN_MS,
      );
    }
  });
};

const handleSessionExpired = () => {
  accessToken.discardAccessToken();
  store.dispatch(userSessionExpired);
};
