import {
  CustomFormattedDate,
  CustomFormattedMessage,
  defaultDateFormat,
} from '../../../i18n/i18n';
import { GridSubHeadline } from '../../../components/pageGrid/GridSubHeadline';
import { GridTwoPanels } from '../../../components/pageGrid/GridTwoPanels';
import { getParkingBookingData } from '../state/parkingBookingSlice';
import { useAppSelector } from '../../../state/store';
import { LANGUAGE_TO_TRANSLATION_KEY } from '../../../i18n/enumMappings';
import { GridPanel } from '../../../components/pageGrid/GridPanel';
import { BookingSummaryNote } from './BookingSummaryNote';
import { MonetaryValue } from '../../../components/MonetaryValue';

export const BookingSummaryDetails = () => {
  const formData = useAppSelector(getParkingBookingData);

  return (
    <>
      <GridSubHeadline
        columns={2}
        dataTestId={'booking-summary-sub-headline'}
      >
        <CustomFormattedMessage
          id={'bookingSummary.subHeadline.vehicleInformation'}
        />
      </GridSubHeadline>
      <GridTwoPanels
        leftColumns={1}
        rightColumns={1}
        leftContent={<CustomFormattedMessage id={'common.label.vehicleName'} />}
        rightContent={formData.vehicleData?.name}
        leftDataTestId={'booking-summary-left-content'}
        rightDataTestId={'booking-summary-right-content'}
      />
      {formData.trailerData?.trailerEnabled ? (
        <>
          <GridTwoPanels
            leftColumns={1}
            rightColumns={1}
            leftContent={<CustomFormattedMessage id={'common.label.trailer'} />}
            rightContent={'Yes'}
            leftDataTestId={'booking-summary-left-content'}
            rightDataTestId={'booking-summary-right-content'}
          />
          <GridTwoPanels
            leftColumns={1}
            rightColumns={1}
            leftContent={
              <CustomFormattedMessage id={'common.label.trailerLicensePlate'} />
            }
            rightContent={formData.trailerData?.trailerLicensePlate}
            leftDataTestId={'booking-summary-left-content'}
            rightDataTestId={'booking-summary-right-content'}
          />
        </>
      ) : (
        <>
          <GridTwoPanels
            leftColumns={1}
            rightColumns={1}
            leftContent={<CustomFormattedMessage id={'common.label.trailer'} />}
            rightContent={'No'}
            leftDataTestId={'booking-summary-left-content'}
            rightDataTestId={'booking-summary-right-content'}
          />
        </>
      )}
      <GridSubHeadline
        columns={2}
        dataTestId={'booking-summary-sub-headline'}
      >
        <CustomFormattedMessage
          id={'bookingSummary.subHeadline.bookingDates'}
        />
      </GridSubHeadline>
      <GridTwoPanels
        leftColumns={1}
        rightColumns={1}
        leftContent={<CustomFormattedMessage id={'common.label.arrival'} />}
        rightContent={
          <CustomFormattedDate
            value={formData.arrival}
            dateFormat={{
              ...defaultDateFormat,
              timeZone: formData.locationData?.timezone,
            }}
          />
        }
        leftDataTestId={'booking-summary-left-content'}
        rightDataTestId={'booking-summary-right-content'}
      />
      <GridTwoPanels
        leftColumns={1}
        rightColumns={1}
        leftContent={<CustomFormattedMessage id={'common.label.departure'} />}
        rightContent={
          <CustomFormattedDate
            value={formData.departure}
            dateFormat={{
              ...defaultDateFormat,
              timeZone: formData.locationData?.timezone,
            }}
          />
        }
        leftDataTestId={'booking-summary-left-content'}
        rightDataTestId={'booking-summary-right-content'}
      />
      <GridSubHeadline
        columns={2}
        dataTestId={'booking-summary-sub-headline'}
      >
        <CustomFormattedMessage
          id={'bookingSummary.subHeadline.additionalInformation'}
        />
      </GridSubHeadline>
      <GridTwoPanels
        leftColumns={1}
        rightColumns={1}
        leftContent={<CustomFormattedMessage id={'common.label.driver'} />}
        rightContent={formData.driverName}
        leftDataTestId={'booking-summary-left-content'}
        rightDataTestId={'booking-summary-right-content'}
      />
      <GridTwoPanels
        leftColumns={1}
        rightColumns={1}
        leftContent={
          <CustomFormattedMessage id={'common.label.tripReference'} />
        }
        rightContent={formData.tripReference}
        leftDataTestId={'booking-summary-left-content'}
        rightDataTestId={'booking-summary-right-content'}
      />

      {formData.entryInstructionsLanguage && (
        <GridTwoPanels
          leftColumns={1}
          rightColumns={1}
          leftContent={
            <CustomFormattedMessage
              id={'common.label.entryInstructionsLanguage'}
            />
          }
          rightContent={
            <CustomFormattedMessage
              id={
                LANGUAGE_TO_TRANSLATION_KEY[formData.entryInstructionsLanguage]
              }
            />
          }
          leftDataTestId={'booking-summary-left-content'}
          rightDataTestId={'booking-summary-right-content'}
        />
      )}

      <>
        <GridSubHeadline
          columns={2}
          dataTestId={'booking-summary-sub-headline'}
        >
          <CustomFormattedMessage id={'bookingSummary.subHeadline.price'} />
        </GridSubHeadline>
        <GridTwoPanels
          leftColumns={1}
          rightColumns={1}
          leftContent={
            <CustomFormattedMessage id={'bookingSummary.bookingPrice'} />
          }
          rightContent={
            formData.estimatedPrice && (
              <MonetaryValue
                amount={Number.parseFloat(formData.estimatedPrice.value)}
                currency={formData.estimatedPrice.currency}
              />
            )
          }
          leftDataTestId={'booking-summary-left-content'}
          rightDataTestId={'booking-summary-right-content'}
        />
        <GridPanel columns={2}>
          <BookingSummaryNote>
            <CustomFormattedMessage
              values={{
                lineBreak: <br />,
              }}
              id={'bookingSummary.priceDisclaimer'}
            />
          </BookingSummaryNote>
        </GridPanel>
      </>
    </>
  );
};
