import { Alert, AlertType } from '../../../components/Alert';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { appConfiguration } from '../../../configuration/appConfiguration';
import { LOCATION_DETAILS_ROUTE } from '../../../routing/routes';
import { useNavigate } from 'react-router-dom';
import React from 'react';

type BookingSuccessfulMessageProps = {
  locationId: string;
};

export const BookingSuccessfulMessage: React.FC<
  BookingSuccessfulMessageProps
> = ({ locationId }) => {
  const navigate = useNavigate();

  const openSimplePayBookingOverview = () => {
    window.open(
      appConfiguration.externalUrls.simplePayBookingOverviewUrl,
      '_blank',
    );
  };

  return (
    <>
      <Alert
        headline={
          <CustomFormattedMessage id={'bookingSummary.success.headline'} />
        }
        body={<CustomFormattedMessage id={'bookingSummary.success.note'} />}
        type={AlertType.SUCCESS}
        className={'margin-bottom-20'}
      />
      <div>
        <Button
          bsStyle="primary"
          onClick={() =>
            navigate(`${LOCATION_DETAILS_ROUTE}?locationId=${locationId}`)
          }
        >
          <CustomFormattedMessage
            id={'bookingSummary.button.locationDetails'}
          />
        </Button>
        <Button
          bsStyle="primary"
          className="margin-left-10"
          onClick={() => openSimplePayBookingOverview()}
        >
          <CustomFormattedMessage
            id={'bookingSummary.button.bookingOverview'}
          />
        </Button>
      </div>
    </>
  );
};
