import React from 'react';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import Button from '@rio-cloud/rio-uikit/Button';
import classNames from 'classnames';
import { Alert, AlertType } from '../../../components/Alert';

type ParkingAvailabilityProps = {
  isFetching: boolean;
  estimatedPrice?: {
    value: string;
    currency: string;
  };
  hasError: boolean;
  onRetryClick: () => void;
};

export const ParkingAvailability: React.FC<ParkingAvailabilityProps> = ({
  isFetching,
  estimatedPrice,
  hasError,
  onRetryClick,
}) => {
  if (isFetching) {
    return (
      <div className={'display-flex gap-10 margin-top-10'}>
        <Spinner isInline />
        <p className={'margin-top-0'}>
          <CustomFormattedMessage
            id={'bookingDetailsPage.parkingAvailability.checking'}
          />
        </p>
      </div>
    );
  }

  if (hasError) {
    return (
      <div
        data-testid="parking-availability-error"
        className={'display-flex gap-10 justify-content-center'}
      >
        <Alert
          className={'width-100pct'}
          headline={
            <CustomFormattedMessage
              id={'bookingDetailsPage.parkingAvailability.error'}
            />
          }
          footer={
            <Button
              bsStyle={Button.DANGER}
              className={classNames({ 'btn-loading disabled': isFetching })}
              onClick={onRetryClick}
            >
              <CustomFormattedMessage id={'common.retry'} />
            </Button>
          }
          type={AlertType.DANGER}
        />
      </div>
    );
  }

  if (!estimatedPrice) {
    return (
      <div className={'display-flex gap-10'}>
        <p
          className={
            'text-color-danger text-size-20 rioglyph rioglyph-calendar'
          }
        />
        <p
          data-testid="parking-availability"
          className={'text-color-danger'}
        >
          <CustomFormattedMessage
            values={{
              lineBreak: <br />,
            }}
            id={'bookingDetailsPage.parkingAvailability.notAvailable'}
          />
        </p>
      </div>
    );
  }

  return (
    <div className={'display-flex gap-10'}>
      <p className={'text-color-success text-size-20 rioglyph rioglyph-ok'} />
      <span>
        {estimatedPrice && (
          <p
            data-testid="parking-availability"
            className={'text-color-success'}
          >
            <CustomFormattedMessage
              values={{
                lineBreak: <br />,
                price: estimatedPrice?.value,
                currency: estimatedPrice?.currency,
              }}
              id={'bookingDetailsPage.parkingAvailability.isAvailable'}
            />
          </p>
        )}
      </span>
    </div>
  );
};
