import IframeResizer from 'iframe-resizer-react';
import { appConfiguration } from '../configuration/appConfiguration';

export const Upselling = () => {
  return (
    <IframeResizer
      src={appConfiguration.externalUrls.upsellingWidgetUrl}
      height={50}
      title={'Upselling Widget'}
    />
  );
};
