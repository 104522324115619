export enum WidgetMode {
  SEARCH = 'SEARCH',
  DETAILS = 'DETAILS',
  NONE = 'NONE',
}

export const useWidgetContext = () => {
  const getWidgetMode = (): WidgetMode => {
    const widgetContainerName = window.name;

    switch (widgetContainerName) {
      case 'simplePaySearch':
      case 'simplePaySearchTest':
        return WidgetMode.SEARCH;
      case 'simplePayDetails':
        return WidgetMode.DETAILS;
      default:
        return WidgetMode.NONE;
    }
  };

  return {
    getWidgetMode,
  };
};
