import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import { useNavigate } from 'react-router-dom';
import { PARKING_BOOKING_DETAILS_ROUTE } from '../../../routing/routes';
import { Grid } from '../../../components/pageGrid/Grid';
import { GridHeadline } from '../../../components/pageGrid/GridHeadline';
import { GridPanel } from '../../../components/pageGrid/GridPanel';
import { BookingSummaryDetails } from './BookingSummaryDetails';
import { BookingSummaryLegalNotice } from './BookingSummaryLegalNotice';
import { BookingSummaryNote } from './BookingSummaryNote';
import classNames from 'classnames';
import {
  BookParkingLotRequest,
  useCreateParkingBookingMutation,
} from '../../../codegen/booking';
import { useAppSelector } from '../../../state/store';
import { getParkingBookingData } from '../state/parkingBookingSlice';
import { BookingSuccessfulMessage } from './BookingSuccessfulMessage';
import { BookingFailedMessage } from './BookingFailedMessage';
import moment from 'moment/moment';

const SEVEN_DAYS = 168;

export const BookingSummaryPage = () => {
  const navigate = useNavigate();
  const [createParkingBooking, { isLoading, isSuccess, isError }] =
    useCreateParkingBookingMutation();
  const formData = useAppSelector(getParkingBookingData);

  const arrivalDateTime = moment(formData.arrival ?? '');
  const departureDateTime = moment(formData.departure ?? '');

  const differenceInHours = departureDateTime.diff(
    arrivalDateTime,
    'hours',
    true,
  );

  const toBookingDetails = () => {
    navigate(PARKING_BOOKING_DETAILS_ROUTE);
  };

  const handleConfirmation = () => {
    const request: BookParkingLotRequest = {
      locationId: formData.locationData!.locationId,
      vehicleInfo: { vehicleId: formData.vehicleData!.id },
      arrivalTime: formData.arrival!,
      departureTime: formData.departure!,
      driverName: formData.driverName!,
      bookingReference: formData.tripReference,
      instructionLanguage: formData.entryInstructionsLanguage!,
    };

    if (formData.trailerData?.trailerLicensePlate) {
      request.trailerInfo = {
        licensePlate: formData.trailerData.trailerLicensePlate,
      };
    }

    createParkingBooking({ bookParkingLotRequest: request });
  };

  return (
    <>
      <div data-testid="parking-booking-summary-content">
        <Grid
          columns={2}
          className={'align-items-top margin-bottom-50'}
        >
          <GridHeadline columns={2}>
            <CustomFormattedMessage id={'bookingSummary.headline'} />
          </GridHeadline>
          <BookingSummaryDetails />
        </Grid>

        {isSuccess ? (
          <BookingSuccessfulMessage
            locationId={formData.locationData!.locationId}
          />
        ) : (
          <>
            <Grid
              columns={1}
              className={'align-items-center margin-bottom-20'}
            >
              <BookingSummaryLegalNotice />
              <GridPanel columns={1}>
                {differenceInHours < SEVEN_DAYS && (
                  <BookingSummaryNote>
                    <CustomFormattedMessage
                      id={'bookingSummary.cancellation.UpTo7Days'}
                    />
                  </BookingSummaryNote>
                )}
                {differenceInHours >= SEVEN_DAYS && (
                  <BookingSummaryNote>
                    <CustomFormattedMessage
                      id={'bookingSummary.cancellation.LongerThan7Days'}
                    />
                  </BookingSummaryNote>
                )}
              </GridPanel>
            </Grid>
            {isError && <BookingFailedMessage />}
            <Button
              bsStyle="default"
              className={'margin-right-10'}
              onClick={toBookingDetails}
            >
              <CustomFormattedMessage id={'common.back'} />
            </Button>
            <Button
              bsStyle="primary"
              className={classNames({
                'btn-loading disabled': isLoading,
              })}
              onClick={handleConfirmation}
            >
              <CustomFormattedMessage
                id={'bookingSummary.button.confirmBooking'}
              />
            </Button>
          </>
        )}
      </div>
    </>
  );
};
