import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../state/store';
import { InstructionLanguage } from '../../../codegen/booking';

export type LocationData = {
  locationId: string;
  timezone: string;
};

export type VehicleData = {
  id: string;
  name: string;
};

type TrailerData = {
  trailerEnabled: boolean;
  trailerLicensePlate?: string;
};

type MonetaryAmount = {
  value: string;
  currency: string;
};

export type BookingDetails = {
  vehicleData?: VehicleData;
  trailerData?: TrailerData;
  arrival?: string;
  departure?: string;
  driverName?: string;
  tripReference?: string;
  entryInstructionsLanguage?: InstructionLanguage;
  estimatedPrice?: MonetaryAmount;
};

export type ParkingBookingState = BookingDetails & {
  locationData?: LocationData;
};

const initialState: ParkingBookingState = {};

export const parkingBookingSlice = createSlice({
  name: 'parkingBooking',
  initialState,
  reducers: {
    enterBookingFlow: (state, action: PayloadAction<LocationData>) => {
      state = { ...initialState };
      state.locationData = action.payload;
      return state;
    },
    resetBookingState: () => initialState,
    setLocationData: (state, action: PayloadAction<LocationData>) => {
      state.locationData = action.payload;
    },
    setVehicleData: (state, action: PayloadAction<VehicleData>) => {
      state.vehicleData = {
        id: action.payload.id,
        name: action.payload.name,
      };
    },
    setBookingDetails: (state, action: PayloadAction<BookingDetails>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const {
  enterBookingFlow,
  resetBookingState,
  setVehicleData,
  setLocationData,
  setBookingDetails,
} = parkingBookingSlice.actions;
export const getParkingBookingData = (state: RootState) => state.parkingBooking;
