import { ReactNode, useState } from 'react';
import classNames from 'classnames';

export enum AlertType {
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
  WARNING = 'WARNING',
}

type AlertProps = {
  headline: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  type: AlertType;
  className?: string;
  dismissible?: boolean;
};

type AlertConfig = { alertColor: string; textColor: string; icon: string };

const alertConfig: {
  [key in AlertType]: AlertConfig;
} = {
  [AlertType.SUCCESS]: {
    alertColor: 'alert-success',
    textColor: 'text-color-success',
    icon: 'rioglyph-ok-sign',
  },
  [AlertType.DANGER]: {
    alertColor: 'alert-danger',
    textColor: 'text-color-danger',
    icon: 'rioglyph-error-sign',
  },
  [AlertType.WARNING]: {
    alertColor: 'alert-warning',
    textColor: 'text-color-warning',
    icon: 'rioglyph-exclamation-sign',
  },
};

export const Alert = ({
  headline,
  body,
  type,
  className,
  footer,
  dismissible = false,
}: AlertProps) => {
  const config = alertConfig[type];
  const [show, setShow] = useState(true);

  return (
    show && (
      <div className={classNames('alert', className, config.alertColor)}>
        {dismissible && (
          <button
            type="button"
            className="btn btn-icon-only close"
            data-dismiss="alert"
            onClick={() => setShow(false)}
          >
            <span className="rioglyph rioglyph-remove" />
          </button>
        )}
        <div className="display-flex gap-10">
          <span
            className={classNames(
              'text-size-h4',
              'rioglyph',
              config.icon,
              config.textColor,
            )}
          />
          <div>
            <strong className="text-size-16">{headline}</strong>
            <div className="margin-y-5">{body}</div>
            {footer}
          </div>
        </div>
      </div>
    )
  );
};
