import { FormattedNumber } from 'react-intl';
import React from 'react';
import { useAppSelector } from '../state/store';
import { getLocale } from '../i18n/localeSlice';

export const monetaryValueOptions = (
  currency: string,
  maximumFractionDigits: number = 2,
): {
  currency: string;
  style: 'currency';
  currencyDisplay: 'narrowSymbol' | 'code';
  maximumFractionDigits: number;
} => {
  return {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits,
  };
};

type MonetaryValueProps = {
  amount: number;
  currency: string;
  maximumFractionDigits?: number;
};

export const MonetaryValue: React.FC<MonetaryValueProps> = ({
  amount,
  currency,
  maximumFractionDigits = 2,
}) => {
  const locale = useAppSelector(getLocale);
  const currencySymbol = Intl.NumberFormat(locale, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency,
  })
    .formatToParts(0)
    .filter((part) => part.type === 'currency')
    .map((part) => part.value)
    .join('');

  if (!isFinite(amount) || isNaN(amount)) {
    return `${currencySymbol} -`;
  }

  const options = monetaryValueOptions(currency, maximumFractionDigits);
  return (
    <FormattedNumber
      value={amount}
      {...options}
    />
  );
};
