import React from 'react';
import { GridSubHeadline } from '../../../components/pageGrid/GridSubHeadline';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import { GridPanel } from '../../../components/pageGrid/GridPanel';
import { LocationServiceIcon } from '../../../components/icons/LocationServiceIcon';
import { ParkingOption } from '../../../codegen/location';
import { PARKING_LOCATION_SERVICE_TO_TRANSLATION_KEY } from '../../../i18n/enumMappings';

type LocationServicesProps = {
  gridMaxColumns: number;
  parkingOptions: ParkingOption[];
};

export const LocationServices: React.FC<LocationServicesProps> = ({
  gridMaxColumns,
  parkingOptions,
}) => (
  <>
    <GridSubHeadline columns={gridMaxColumns}>
      <CustomFormattedMessage id="locationDetails.services" />
    </GridSubHeadline>
    {Object.values(ParkingOption).map((parkingOption) => (
      <GridPanel
        key={parkingOption}
        columns={3}
      >
        <div className={'text-size-12 display-flex align-items-center'}>
          <LocationServiceIcon
            parkingOption={parkingOption as ParkingOption}
            className={'height-30 margin-right-10 margin-bottom-0'}
            enabled={parkingOptions.includes(parkingOption)}
          />
          <CustomFormattedMessage
            id={
              PARKING_LOCATION_SERVICE_TO_TRANSLATION_KEY[
                parkingOption as ParkingOption
              ]
            }
          />
        </div>
      </GridPanel>
    ))}
  </>
);
