import Popover from '@rio-cloud/rio-uikit/Popover';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import React, { ReactNode } from 'react';
import { Placement } from '@rio-cloud/rio-uikit/values/Placement';

type InfoPopupProps = {
  maxWidth?: number;
  placement?: Placement;
  children: ReactNode;
} & Record<string, string | ReactNode | undefined>;

export const InfoPopup: React.FC<InfoPopupProps> = ({
  children,
  maxWidth = 250,
  placement = 'bottom',
  ...rest
}) => {
  const { 'data-testid': dataTestId } = rest;

  let paddingInfo = '';
  switch (placement) {
    case 'bottom':
    case 'bottom-end':
      paddingInfo = 'padding-bottom-10 padding-top-10';
      break;
    case 'right':
      paddingInfo = 'padding-right-10';
      break;
  }

  return (
    <OverlayTrigger
      trigger="click"
      placement={placement}
      key={'info-popup-popover'}
      overlay={
        <Popover id={'info-popup-popover'}>
          <div className={`display-flex flex-wrap max-width-${maxWidth}`}>
            {children}
          </div>
        </Popover>
      }
    >
      <div className={'display-flex align-items-center pull-right'}>
        <span
          data-testid={dataTestId}
          className={
            'rioglyph rioglyph-info-sign text-color-info text-size-20 ' +
            `cursor-pointer ${paddingInfo}`
          }
        />
      </div>
    </OverlayTrigger>
  );
};
