import React from 'react';
import { CustomFormattedMessage } from '../../i18n/i18n';

type OptionalLabelProps = {
  'className'?: string;
  'data-testid'?: string;
};

export const OptionalLabel: React.FC<OptionalLabelProps> = (props) => {
  const { className } = props;

  const testid = props['data-testid'];

  return (
    <span
      data-testid={testid}
      className={className}
    >
      (<CustomFormattedMessage id={'common.label.optional'} />)
    </span>
  );
};
