import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../state/store';
import { DEFAULT_LOCALE } from './i18n';

export type LocaleState = {
  locale: string;
};

const initialState: LocaleState = {
  locale: DEFAULT_LOCALE,
};

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<string>) => {
      const locale = action.payload;

      const html = document.querySelector('html');

      if (html && locale && html.getAttribute('lang') !== locale) {
        html.setAttribute('lang', locale);
      }

      state.locale = locale;
    },
  },
});

export const { setLocale } = localeSlice.actions;
export const getLocale = (state: RootState) => state.locale.locale;
