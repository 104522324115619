import * as RioDatePicker from '@rio-cloud/rio-uikit/DatePicker';
import { Moment } from 'moment/moment';

export const DATE_FORMAT = 'DD/MM/YYYY';

type DatePickerProps = {
  value?: string | Moment | Date;
  onChange?: (value: string | Moment, isValid: boolean) => void;
  isValidDate?: (currentDate: Moment) => boolean;
};

export const DatePicker = ({
  value,
  onChange,
  isValidDate,
}: DatePickerProps) => {
  return (
    <RioDatePicker.default
      value={value}
      onChange={onChange}
      timeFormat={false}
      isValidDate={isValidDate}
      dateFormat={DATE_FORMAT}
      inputProps={{ placeholder: DATE_FORMAT }}
      className="margin-0"
    />
  );
};
