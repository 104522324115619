import React from 'react';
import adrIcon from '../../assets/icons/ic_simplepay_adr.svg';
import bedIcon from '../../assets/icons/ic_simplepay_bed.svg';
import coolingIcon from '../../assets/icons/ic_simplepay_cooling.svg';
import electricityIcon from '../../assets/icons/ic_simplepay_electricity.svg';
import fenceIcon from '../../assets/icons/ic_simplepay_fence.svg';
import lightingIcon from '../../assets/icons/ic_simplepay_lighting.svg';
import longtermIcon from '../../assets/icons/ic_simplepay_parking_longterm.svg';
import restaurantIcon from '../../assets/icons/ic_simplepay_restaurant.svg';
import securityIcon from '../../assets/icons/ic_simplepay_security.svg';
import showerIcon from '../../assets/icons/ic_simplepay_shower.svg';
import toileteIcon from '../../assets/icons/ic_simplepay_toilete.svg';
import trailerIcon from '../../assets/icons/ic_simplepay_trailer_parking.svg';
import videoIcon from '../../assets/icons/ic_simplepay_video.svg';
import wifiIcon from '../../assets/icons/ic_simplepay_wifi.svg';
import adrIconCrossed from '../../assets/icons/ic_simplepay_adr_crossed.svg';
import bedIconCrossed from '../../assets/icons/ic_simplepay_bed_crossed.svg';
import coolingIconCrossed from '../../assets/icons/ic_simplepay_cooling_crossed.svg';
import electricityIconCrossed from '../../assets/icons/ic_simplepay_electricity_crossed.svg';
import fenceIconCrossed from '../../assets/icons/ic_simplepay_fence_crossed.svg';
import lightingIconCrossed from '../../assets/icons/ic_simplepay_lighting_crossed.svg';
import longtermIconCrossed from '../../assets/icons/ic_simplepay_parking_longterm_crossed.svg';
import restaurantIconCrossed from '../../assets/icons/ic_simplepay_restaurant_crossed.svg';
import securityIconCrossed from '../../assets/icons/ic_simplepay_security_crossed.svg';
import showerIconCrossed from '../../assets/icons/ic_simplepay_shower_crossed.svg';
import toileteIconCrossed from '../../assets/icons/ic_simplepay_toilete_crossed.svg';
import trailerIconCrossed from '../../assets/icons/ic_simplepay_trailer_parking_crossed.svg';
import videoIconCrossed from '../../assets/icons/ic_simplepay_video_crossed.svg';
import wifiIconCrossed from '../../assets/icons/ic_simplepay_wifi_crossed.svg';
import { ParkingOption } from '../../codegen/location';

type LocationServiceIconProps = {
  parkingOption: ParkingOption;
  className: string;
  enabled: boolean;
};

const enabledOptionToIcon: { [key in ParkingOption]: string } = {
  COOLING_MOTORS_ALLOWED: coolingIcon,
  ELECTRIC_COOLING_CHARGING: electricityIcon,
  ADR_POSSIBLE: adrIcon,
  LONG_TIME_PARKING: longtermIcon,
  DECOUPLING_TRAILERS: trailerIcon,
  FENCE: fenceIcon,
  SPREAD_LIGHTING: lightingIcon,
  CAMERA_SECURITY: videoIcon,
  GUARD: securityIcon,
  TOILET: toileteIcon,
  SHOWER: showerIcon,
  WIFI: wifiIcon,
  HOTEL: bedIcon,
  RESTAURANT: restaurantIcon,
};

const disabledOptionToIcon: { [key in ParkingOption]: string } = {
  COOLING_MOTORS_ALLOWED: coolingIconCrossed,
  ELECTRIC_COOLING_CHARGING: electricityIconCrossed,
  ADR_POSSIBLE: adrIconCrossed,
  LONG_TIME_PARKING: longtermIconCrossed,
  DECOUPLING_TRAILERS: trailerIconCrossed,
  FENCE: fenceIconCrossed,
  SPREAD_LIGHTING: lightingIconCrossed,
  CAMERA_SECURITY: videoIconCrossed,
  GUARD: securityIconCrossed,
  TOILET: toileteIconCrossed,
  SHOWER: showerIconCrossed,
  WIFI: wifiIconCrossed,
  HOTEL: bedIconCrossed,
  RESTAURANT: restaurantIconCrossed,
};

const getLocationServiceIcon = (
  parkingOption: ParkingOption,
  enabled: boolean,
): string => {
  return enabled
    ? enabledOptionToIcon[parkingOption]
    : disabledOptionToIcon[parkingOption];
};
export const LocationServiceIcon: React.FC<LocationServiceIconProps> = ({
  parkingOption,
  className,
  enabled,
}) => (
  <img
    alt={parkingOption}
    src={getLocationServiceIcon(parkingOption, enabled)}
    className={className}
    data-testid={`parking-option-${parkingOption}-${enabled}`}
  />
);
