import { CustomFormattedMessage, TranslationsKeys } from '../i18n/i18n';
import Notification from '@rio-cloud/rio-uikit/Notification';

export const notifyError = (
  title: TranslationsKeys,
  message: TranslationsKeys,
) => {
  Notification.error(
    <CustomFormattedMessage id={message} />,
    <CustomFormattedMessage id={title} />,
  );
};
