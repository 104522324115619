import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { accessToken } from './accessToken';
import { SessionRenewedResult } from './login';

export type AccessToken = string | undefined | null;

export interface TokenState {
  accessToken: AccessToken;
  userSessionExpired: boolean;
}

const initialState: TokenState = {
  accessToken: accessToken.getAccessToken(),
  userSessionExpired: false,
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    userSessionExpired: (state) => {
      state.userSessionExpired = true;
    },
    renewUserSession: (state, action: PayloadAction<SessionRenewedResult>) => {
      state.accessToken = action.payload.accessToken;
      state.userSessionExpired = false;
    },
  },
});

export const { userSessionExpired, renewUserSession } = tokenSlice.actions;
