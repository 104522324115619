const configureRouteStorage = (window: Window) => {
  const { localStorage } = window;
  const routeBeforeRedirectKey = 'route_before_redirect';

  return {
    getRoute: () => localStorage.getItem(routeBeforeRedirectKey),
    saveRoute: (route: string) =>
      localStorage.setItem(routeBeforeRedirectKey, route),
  };
};

export const routeStorage = configureRouteStorage(window);
