import { TranslationsKeys } from './i18n';
import { ParkingOption, Weekday } from '../codegen/location';
import { InstructionLanguage } from '../codegen/booking';

type EnumMapping<T extends string, S> = {
  [key in T]: S;
};

type EnumTranslation<R extends string> = EnumMapping<R, TranslationsKeys>;

export const PARKING_LOCATION_SERVICE_TO_TRANSLATION_KEY: EnumTranslation<ParkingOption> =
  {
    COOLING_MOTORS_ALLOWED:
      'locationDetails.locationServices.COOLING_MOTORS_ALLOWED',
    ELECTRIC_COOLING_CHARGING:
      'locationDetails.locationServices.ELECTRIC_COOLING_CHARGING',
    ADR_POSSIBLE: 'locationDetails.locationServices.ADR_POSSIBLE',
    LONG_TIME_PARKING: 'locationDetails.locationServices.LONG_TIME_PARKING',
    DECOUPLING_TRAILERS: 'locationDetails.locationServices.DECOUPLING_TRAILERS',
    FENCE: 'locationDetails.locationServices.FENCE',
    SPREAD_LIGHTING: 'locationDetails.locationServices.SPREAD_LIGHTING',
    CAMERA_SECURITY: 'locationDetails.locationServices.CAMERA_SECURITY',
    GUARD: 'locationDetails.locationServices.GUARD',
    TOILET: 'locationDetails.locationServices.TOILET',
    SHOWER: 'locationDetails.locationServices.SHOWER',
    WIFI: 'locationDetails.locationServices.WIFI',
    HOTEL: 'locationDetails.locationServices.HOTEL',
    RESTAURANT: 'locationDetails.locationServices.RESTAURANT',
  };

export const WEEKDAYS_TO_TRANSLATION_KEY: EnumTranslation<Weekday> = {
  MONDAY: 'common.weekday.monday',
  TUESDAY: 'common.weekday.tuesday',
  WEDNESDAY: 'common.weekday.wednesday',
  THURSDAY: 'common.weekday.thursday',
  FRIDAY: 'common.weekday.friday',
  SATURDAY: 'common.weekday.saturday',
  SUNDAY: 'common.weekday.sunday',
};

export const LANGUAGE_TO_TRANSLATION_KEY: EnumTranslation<InstructionLanguage> =
  {
    cs: 'language.czech',
    da: 'language.danish',
    de: 'language.german',
    en: 'language.english',
    es: 'language.spanish',
    fr: 'language.french',
    hu: 'language.hungarian',
    it: 'language.italian',
    nl: 'language.dutch',
    pl: 'language.polish',
    pt: 'language.portuguese',
    ro: 'language.romanian',
    ru: 'language.russian',
    uk: 'language.ukrainian',
  };

export const LANGUAGE_TO_FLAG: EnumMapping<InstructionLanguage, string> = {
  cs: '🇨🇿',
  da: '🇩🇰',
  de: '🇩🇪',
  en: '🇬🇧',
  es: '🇪🇸',
  fr: '🇫🇷',
  hu: '🇭🇺',
  it: '🇮🇹',
  nl: '🇳🇱',
  pl: '🇵🇱',
  pt: '🇭🇺',
  ro: '🇷🇴',
  ru: '🇷🇺',
  uk: '🇺🇦',
};
