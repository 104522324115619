import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './state/store';
import { login } from './state/login/login';
import { RouterProvider } from 'react-router-dom';
import { routing } from './routing/routing';

const container = document.getElementById('root')!;
const root = createRoot(container);

login().then((user) => {
  if (!user) {
    return <></>;
  }

  return root.render(
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={routing} />
      </Provider>
    </React.StrictMode>,
  );
});
